<template>
  <div>

    <div class="card-toolbar mb-5">
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20" v-if="showAdvancedSearch">
          <div class="row mb-5">

            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="name">{{ $t('receipt_document.name') }}</label>
              <input v-model="filters.name" type="text" id="name" class="form-control">
            </div>
            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="receipt_number">{{ $t('receipt_document.receipt_number') }}</label>
              <input v-model="filters.receipt_number" type="text" id="receipt_number" class="form-control">
            </div>
            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="receipt_number">{{ $t('receipt_document.purchase_code') }}</label>
              <input v-model="filters.invoice_code" type="text" id="receipt_number" class="form-control">
            </div>
            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="from_date">{{ $t('receipt_document.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="to_date">{{ $t('receipt_document.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>


            <div class="form-group col-md-4 mt-2 mb-2">
              <label for="f_status">{{ $t('status') }}</label>
              <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
              </select>
            </div>


            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                              <span><i class="fas fa-search"></i>
                                  <span>{{ $t('search') }}</span>
                              </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                              <span><i class="fas fa-trash-restore"></i>
                                  <span>{{ $t('reset_search') }}</span>
                              </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::customer-->
    <div class="card card-custom">
      <div class="card-body">

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

          <template slot="status" slot-scope="props">
            <button class="btn btn-secondary m-btn m-btn--icon w-auto" v-if="$can('receipt_documents.update')" @click="showModal(props.row.id, props.row.status)" :key="props.row.id">
              {{ $t('change_status') }}
              ({{ props.row.status_name }})
            </button>
          </template>
          <template slot="actions" slot-scope="props">
            <v-btn v-b-tooltip.hover :title="$t('edit')" icon color="pink"  v-if="$can('receipt_documents.update')" :to="`/purchases/receipt-documents/edit/${props.row.id}`">
              <v-icon small class="mr-2 text-info">mdi-pencil</v-icon>
            </v-btn>
            <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('receipt_documents.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>

          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
    <b-modal ref="modal" hide-footer :title="$t('change_status')">

      <div class="form-group row">
        <div class="col-lg-12 mb-5">
          <label>{{ $t('status') }}</label>
          <select name="status" id="status" v-model="status_changed" class="custom-select">
            <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
          </select>
        </div>
      </div>

      <div class="card-footer pl-0 pr-0 pb-0">
        <div class="row">
          <div class="col-lg-6">
            <button class="btn btn-primary mr-2" @click="changeStatus">{{ $t('save') }}</button>
            <button class="btn btn-secondary" @click="hideModal">{{ $t('close') }}</button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-receipt-documents",
  components: {},
  data() {
    return {
      mainRoute: 'purchases/receipt-documents',
      routeChangeStatus: 'purchases/receipt-document/change-status',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,

      filter: {
        sortBy: 'id',
      },
      filters: {
        name: null,
        receipt_number: null,
        from_date: null,
        invoice_code: null,
        to_date: null,
        status: null,
      },
      status_list: [],
      columns: ['name', 'receipt_date', 'receipt_number', 'invoice_code', 'status', 'actions'],
      data: [],
      id_changed: null,
      status_changed: null,

    }
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          name: that.$t('receipt_document.name'),
          receipt_date: that.$t('receipt_document.receipt_date'),
          receipt_number: that.$t('receipt_document.receipt_number'),
          invoice_code: that.$t('receipt_document.purchase_code'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.receipt_documents")}]);
    this.getStatus();
  },
  methods: {

    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.name = null;
      this.filters.receipt_number = null;
      this.filters.from_date = null;
      this.filters.invoice_code = null;
      this.filters.to_date = null;
      this.filters.status = null;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },

    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },


    actionDelete(item) {
      ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    changeStatus() {
      ApiService.patch(this.routeChangeStatus + '/' + this.id_changed, {
        status: this.status_changed,
      }).then(response => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
        this.hideModal();
      }).catch(error => {
        this.$errorAlert(error);
      });
    },
    showModal(id, status) {
      this.$refs['modal'].show();
      this.id_changed = id;
      this.status_changed = status;
    },
    hideModal() {
      this.$refs['modal'].hide();
      this.id_changed = null;
      this.status_changed = null;
    },

    getStatus() {
      ApiService.get(this.mainRouteDependency + "/receipt_document_status").then((response) => {
        this.status_list = response.data.data;
      });
    },
  },
};
</script>
